/* src/Loading.css */
.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
    z-index: 9999;
  }
  
  .loading-image {
    width: 100px; /* Adjust the size of the loading image */
    height: 100px;
    animation: spin 1s linear infinite; /* Optional: Add spin animation */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .loading-screen h2 {
    color: white;
    margin-top: 20px;
  }
  