/* ChatFrame.css */

/* Base Variables */
:root {
  /* Light mode colors */
  --bg-primary-light: #ffffff;
  --bg-secondary-light: #f3f4f6;
  --text-primary-light: #1f2937;
  --text-secondary-light: #4b5563;
  --border-light: rgba(0, 0, 0, 0.1);
  --message-sent-light: #2563eb;
  --message-received-light: #f3f4f6;
  --date-separator-light: rgba(0, 0, 0, 0.05);
  
  /* Dark mode colors */
  --bg-primary-dark: #1a1b1e;
  --bg-secondary-dark: #2a2b2e;
  --text-primary-dark: #e5e7eb;
  --text-secondary-dark: #9ca3af;
  --border-dark: rgba(255, 255, 255, 0.1);
  --message-sent-dark: #3b82f6;
  --message-received-dark: #374151;
  --date-separator-dark: rgba(255, 255, 255, 0.1);
}

/* Overall chat frame styling */
.chat-frame {
  display: flex;
  flex-direction: column;
  height: calc(100vh - var(--nav-height-desktop));
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: var(--nav-height-desktop);
  background: var(--bg-primary-light);
  position: relative;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.chat-header {
  height: 70px;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--border-light);
  background: var(--bg-primary-light);
  border-radius: 8px 8px 0 0;
}

.chat-header-left {
  display: flex;
  align-items: center;
  gap: 3rem;
}

.chat-header-left h2 {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text-primary-light);
  margin: 0;
}

.status-indicator {
  font-size: 0.875rem;
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  background: var(--bg-secondary-light);
  color: var(--text-secondary-light);
}

.status-indicator.online {
  background: rgba(16, 185, 129, 0.1);
  color: #10b981;
}

.status-indicator.offline {
  background: rgba(239, 68, 68, 0.1);
  color: #ef4444;
}

.chat-header-right {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.icon-button {
  background: transparent;
  border: none;
  padding: 0.5rem;
  border-radius: 0.5rem;
  color: var(--text-secondary-light);
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease;
}

.icon-button:hover {
  background: var(--bg-secondary-light);
  color: var(--primary-color);
}

.notification-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background: #ef4444;
  color: white;
  font-size: 0.75rem;
  padding: 0.125rem 0.375rem;
  border-radius: 1rem;
  min-width: 18px;
  text-align: center;
}

.chat-content {
  flex: 1;
  overflow: hidden;
  position: relative;
}

.chat-messages {
  height: 100%;
  overflow-y: auto;
  padding: 1.5rem;
  scroll-behavior: smooth;
  background: var(--bg-primary-light);
}

.chat-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.settings-panel {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 300px;
  background: var(--bg-primary-light);
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  z-index: 10;
}

.offline-indicator {
  position: absolute;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
  background: #ef4444;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  animation: slideUp 0.3s ease;
  z-index: 100;
}

@keyframes slideUp {
  from {
    transform: translate(-50%, 100%);
    opacity: 0;
  }
  to {
    transform: translate(-50%, 0);
    opacity: 1;
  }
}

/* Dark mode enhancements */
@media (prefers-color-scheme: dark) {
  .chat-frame {
    background: var(--bg-primary-dark);
    color: var(--text-primary-dark);
    border-color: var(--border-dark);
  }

  .chat-header {
    background: var(--bg-primary-dark);
    border-color: var(--border-dark);
  }

  .icon-button {
    color: var(--text-secondary-dark);
  }

  .icon-button:hover {
    background: var(--bg-secondary-dark);
  }

  .settings-panel {
    background: var(--bg-primary-dark);
    border-color: var(--border-dark);
  }
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .chat-frame {
    height: calc(100vh - var(--nav-height-mobile));
    margin-top: var(--nav-height-mobile);
    border-radius: 0;
  }

  .chat-header {
    padding: 0.75rem 1rem;
    height: 60px;
    border-radius: 0;
  }

  .settings-panel {
    width: 100%;
  }

  .chat-messages {
    height: calc(100vh - var(--nav-height-mobile) - 120px);
  }

  .chat-header,
  .chat-controls {
    height: 60px;
  }

  .chat-controls {
    border-radius: 0;
  }
}

/* Animations */
.chat-frame {
  transition: all 0.3s ease;
}

.chat-frame:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-lg);
}

.icon-button svg {
  transition: transform 0.2s ease;
}

.icon-button:hover svg {
  transform: scale(1.1);
}

/* Message styling */
.message-item {
  display: flex;
  flex-direction: column;
  max-width: 65%;
  animation: fadeIn 0.3s ease;
}

.message-item.sent {
  align-self: flex-end;
}

.message-item.received {
  align-self: flex-start;
}

.message-content {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.message-user {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.25rem;
}

.user-avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: var(--primary-color);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  font-weight: 500;
}

.user-name {
  font-size: 0.875rem;
  color: var(--text-secondary-light);
  font-weight: 500;
}

.message-bubble {
  background: var(--bg-secondary-light);
  padding: 0.75rem 1rem;
  border-radius: 1.25rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.sent .message-bubble {
  background: var(--primary-color);
  color: white;
}

.message-text {
  font-size: 1rem;
  line-height: 1.5;
  white-space: pre-wrap;
  word-break: break-word;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.message-emoji {
  display: inline-block;
  font-size: 1.4rem;
  line-height: 1;
  vertical-align: middle;
  margin: 0 0.1rem;
  font-family: "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", -apple-system;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
}

.sent .message-emoji {
  color: inherit;
}

.message-link {
  color: var(--primary-color);
  text-decoration: none;
  word-break: break-all;
}

.sent .message-link {
  color: white;
  text-decoration: underline;
}

.message-meta {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: var(--text-secondary-light);
  justify-content: flex-end;
}

.sent .message-meta {
  color: rgba(255, 255, 255, 0.9);
}

.message-time {
  white-space: nowrap;
}

.message-status {
  display: flex;
  align-items: center;
}

.status-icon {
  font-size: 0.875rem;
}

/* Dark mode adjustments */
@media (prefers-color-scheme: dark) {
  .chat-messages {
    background: var(--bg-primary-dark);
  }

  .message-bubble {
    background: var(--bg-secondary-dark);
  }

  .date-line {
    background: var(--border-dark);
  }

  .message-link {
    color: var(--primary-color-light);
  }
}

/* Mobile Responsive */
@media (max-width: 768px) {
  .chat-messages {
    padding: 1rem;
  }

  .message-item {
    max-width: 80%;
  }

  .date-line {
    max-width: 60px;
  }

  .message-emoji {
    font-size: 1.2rem;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Update controls styles */
.chat-controls {
  height: 70px;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  border-top: 1px solid var(--border-light);
  background: var(--bg-primary-light);
  border-radius: 0 0 8px 8px;
}

/* Dark mode updates */
@media (prefers-color-scheme: dark) {
  .chat-header,
  .chat-controls {
    background: var(--bg-primary-dark);
    border-color: var(--border-dark);
  }
}

/* Mobile updates */
@media (max-width: 768px) {
  .chat-content {
    height: calc(100vh - var(--nav-height-mobile) - 120px);
  }

  .chat-header,
  .chat-controls {
    height: 60px;
  }
}

/* Loading Spinner */
.loading-spinner {
  border: 3px solid var(--bg-secondary-light);
  border-top: 3px solid var(--message-sent-light);
}

@media (prefers-color-scheme: dark) {
  .loading-spinner {
    border: 3px solid var(--bg-secondary-dark);
    border-top: 3px solid var(--message-sent-dark);
  }
}

/* Scrollbar */
.chat-messages::-webkit-scrollbar {
  width: 8px;
}

.chat-messages::-webkit-scrollbar-track {
  background: var(--bg-secondary-light);
}

.chat-messages::-webkit-scrollbar-thumb {
  background: var(--text-secondary-light);
  border-radius: 4px;
}

@media (prefers-color-scheme: dark) {
  .chat-messages::-webkit-scrollbar-track {
    background: var(--bg-secondary-dark);
  }

  .chat-messages::-webkit-scrollbar-thumb {
    background: var(--text-secondary-dark);
  }
}

.chat-container {
  display: flex;
  height: 100%;
}

.chat-main {
  flex: 1;
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .chat-container {
    position: relative;
  }
  
  .chat-list-container {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 100;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
  }
  
  .chat-list-container.open {
    transform: translateX(0);
  }
}

.chat-container {
  position: relative;
  height: 100%;
}

.chat-list-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.1);
}

.users-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px !important;
  height: 32px !important;
  padding: 0 !important;
  margin-right: 1rem;
  background: transparent;
  border: none;
  cursor: pointer;
  color: var(--text-primary-light);
  transition: all 0.2s ease;
  border-radius: 50%;
  position: relative;
  z-index: 1001;
}

.users-button svg {
  width: 13.33px;
  height: 13.33px;
}

.users-button:hover {
  background: var(--bg-secondary-light);
}

.users-button.active {
  background: var(--primary-color);
  color: white;
}

.chat-header-left {
  display: flex;
  align-items: center;
  gap: 1rem;
}

/* Dark mode */
@media (prefers-color-scheme: dark) {
  .chat-list-wrapper {
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.3);
  }

  .users-button {
    color: var(--text-primary-dark);
  }
}

/* Overlay when chat list is open */
.chat-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.chat-list-wrapper.open + .chat-overlay {
  display: block;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .chat-list-wrapper {
    width: 280px;
  }
}

.date-separator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
  gap: 1rem;
}

.date-line {
  flex: 1;
  height: 1px;
  background: var(--border-light);
  max-width: 100px;
}

.date-text {
  color: var(--text-secondary-light);
  font-size: 0.875rem;
  font-weight: 500;
  white-space: nowrap;
}

.message-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 900px;
  margin: 0 auto;
}

