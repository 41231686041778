/* Updates Container */
.updates-container {
    max-width: 1400px;
    margin: 80px auto 0;
    padding: 2rem;
    min-height: 100vh;
    background: #111111;
    color: #ffffff;
  }
  
  .updates-header {
    text-align: center;
    margin-bottom: 4rem;
  }
  
  .updates-header h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    background: linear-gradient(135deg, #72da37 0%, #4ca819 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .updates-header p {
    color: #999;
    font-size: 1.2rem;
  }
  
  /* Updates Grid */
  .updates-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin-bottom: 4rem;
  }
  
  .update-card {
    background: #1a1a1a;
    border-radius: 12px;
    padding: 2rem;
    border: 1px solid #333;
    display: flex;
    gap: 1.5rem;
    transition: all 0.3s ease;
  }
  
  .update-card:hover {
    border-color: #72da37;
    transform: translateY(-5px);
  }
  
  .update-icon {
    font-size: 2rem;
    color: #72da37;
  }
  
  .update-content h3 {
    margin-bottom: 1rem;
    color: #fff;
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .update-card-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #fff;
    margin-bottom: 1rem;
  }
  
  .update-content p {
    color: #999;
    margin-bottom: 1rem;
  }
  
  .update-date {
    color: #72da37;
    font-size: 0.9rem;
  }
  
  /* Team Section */
  .team-section {
    margin-bottom: 4rem;
  }
  
  .team-section h2 {
    text-align: center;
    margin-bottom: 2rem;
    color: #fff;
  }
  
  .team-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 2rem;
  }
  
  .team-card {
    background: #1a1a1a;
    border-radius: 12px;
    overflow: hidden;
    border: 1px solid #333;
    transition: all 0.3s ease;
  }
  
  .team-image-container {
    width: 100%;
    height: 250px;
    overflow: hidden;
  }
  
  .team-image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .team-info {
    padding: 1.5rem;
  }
  
  .team-info h3 {
    color: #fff;
    margin-bottom: 0.5rem;
  }
  
  .team-info h4 {
    color: #72da37;
    margin-bottom: 1rem;
  }
  
  .team-info p {
    color: #999;
    margin-bottom: 1rem;
  }
  
  .team-skills {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  
  .skill-tag {
    background: rgba(114, 218, 55, 0.1);
    color: #72da37;
    padding: 0.3rem 0.8rem;
    border-radius: 4px;
    font-size: 0.9rem;
  }
  
  .team-icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: #72da37;
    font-size: 1.5rem;
  }
  
  /* Coming Soon Section */
  .coming-soon {
    text-align: center;
    padding: 4rem 0;
  }
  
  .coming-soon h2 {
    color: #fff;
    margin-bottom: 1rem;
  }
  
  .coming-soon p {
    color: #999;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .updates-header h1 {
      font-size: 2rem;
    }
  
    .team-grid {
      grid-template-columns: 1fr;
    }
  
    .updates-grid {
      grid-template-columns: 1fr;
    }
  }
