/* Main Container */
.contact-container {
  max-width: 1200px;
  margin: 80px auto 0;
  padding: 2rem;
  min-height: 100vh;
  background: #111111;
}

/* Header Section */
.contact-header {
  text-align: center;
  margin-bottom: 3rem;
}

.contact-header h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #72da37;
}

.contact-header p {
  color: #999;
  font-size: 1.1rem;
  max-width: 600px;
  margin: 0 auto;
}

/* Content Grid */
.contact-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

/* Info Cards */
.info-cards {
  display: grid;
  gap: 1rem;
}

.info-card {
  background: #1a1a1a;
  padding: 1.5rem;
  border-radius: 10px;
  border: 1px solid #333;
  transition: transform 0.3s ease;
}

.info-card:hover {
  transform: translateY(-5px);
  border-color: #72da37;
}

.info-icon {
  color: #72da37;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.info-card h3 {
  color: #fff;
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
}

.info-card p {
  color: #999;
}

/* Social Contact Buttons */
.social-contacts {
  display: grid;
  gap: 1rem;
  margin-top: 2rem;
}

.social-contact-btn {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: #1a1a1a;
  border: 1px solid #333;
  border-radius: 8px;
  color: #fff;
  text-decoration: none;
  transition: all 0.3s ease;
}

.social-contact-btn:hover {
  background: var(--hover-color);
  transform: translateX(5px);
}

/* Contact Form */
.contact-form {
  background: #1a1a1a;
  padding: 2rem;
  border-radius: 10px;
  border: 1px solid #333;
}

/* Form Styling Enhancements */
.form-group {
  margin-bottom: 1.5rem;
  position: relative;
}

.input-icon {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #72da37;
  z-index: 1;
}

input, textarea {
  width: 100%;
  padding: 1rem 1rem 1rem 3rem;
  background: #111111;
  border: 1px solid #333;
  border-radius: 8px;
  color: #fff;
  font-size: 1rem;
  transition: all 0.3s ease;
}

textarea {
  min-height: 120px;
  padding: 1rem;
  resize: vertical;
}

input:focus, textarea:focus {
  border-color: #72da37;
  outline: none;
  box-shadow: 0 0 0 2px rgba(114, 218, 55, 0.1);
}

/* Input Placeholder Styling */
input::placeholder,
textarea::placeholder {
  color: #666;
  transition: color 0.3s ease;
}

input:focus::placeholder,
textarea:focus::placeholder {
  color: #999;
}

/* Form Group Hover Effect */
.form-group:hover .input-icon {
  color: #4ca819;
}

/* Submit Button */
.submit-btn {
  width: 100%;
  padding: 1rem;
  background: #72da37;
  border: none;
  border-radius: 8px;
  color: #111;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.submit-btn:hover {
  background: #4ca819;
}

.submit-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Alert Messages */
.alert {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  padding: 1rem 2rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  z-index: 1000;
}

.alert.success {
  background: #72da37;
  color: #111;
}

.alert.error {
  background: #ff453a;
  color: #fff;
}

/* Responsive Design */
@media (max-width: 768px) {
  .contact-content {
    grid-template-columns: 1fr;
  }

  .contact-header h1 {
    font-size: 2rem;
  }

  .contact-form {
    padding: 1.5rem;
  }

  .info-cards {
    grid-template-columns: 1fr;
  }
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

.contact-container {
  animation: fadeIn 0.5s ease-out;
}
  