/* First, import Google Fonts at the top of your CSS file */
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@700&family=Poppins:wght@300;600&family=Russo+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;500&display=swap');

/* Progress Bar */
.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  background: linear-gradient(to right, #72da37, #4ca819);
  transform-origin: 0%;
  z-index: 1000;
}

/* Container */
.home-container {
  min-height: 100vh;
  background: #111111;
  color: #ffffff;
  padding-top: 80px;
}

/* Hero Section */
.hero-section {
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  position: relative;
  overflow: hidden;
}

.hero-content {
  text-align: center;
  max-width: 800px;
  z-index: 1;
}

.hero-title {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  text-align: center;
  margin-bottom: 2rem;
  line-height: 1.2;
  background: linear-gradient(135deg, #f6f9fc 0%, #eef2f7 100%);
  -webkit-background-clip: text;
  color: transparent;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.hero-text-building {
  font-family: 'Russo One', sans-serif;
  font-size: 4rem;
  background: linear-gradient(45deg, #72da37, #4ca819);
  -webkit-background-clip: text;
  color: transparent;
}

.hero-text-the {
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  font-size: 2.5rem;
  opacity: 0.7;
}

.hero-text-future {
  font-family: 'Orbitron', sans-serif;
  font-size: 3.8rem;
  background: linear-gradient(45deg, #013220, #026440);
  -webkit-background-clip: text;
  color: transparent;
  letter-spacing: 2px;
}

.hero-text-with {
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  font-size: 2.5rem;
  opacity: 0.7;
}

.hero-text-technology {
  font-family: 'Russo One', sans-serif;
  font-size: 3.5rem;
  background: linear-gradient(45deg, #72da37, #013220);
  -webkit-background-clip: text;
  color: transparent;
}

.hero-subtitle {
  font-family: 'Space Grotesk', sans-serif;
  font-size: 1.5rem;
  text-align: center;
  color: #666;
  margin: 1.5rem 0;
  letter-spacing: 0.5px;
  line-height: 1.4;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.subtitle-highlight {
  background: linear-gradient(120deg, #72da37 0%, #026440 100%);
  -webkit-background-clip: text;
  color: transparent;
  font-weight: 500;
  position: relative;
  display: inline-block;
}

.subtitle-highlight::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, #72da37 0%, #026440 100%);
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
}

.subtitle-highlight:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

.hero-cta {
  display: flex;
  gap: 1.5rem;
  justify-content: center;
}

/* Buttons */
.primary-btn, .secondary-btn {
  padding: 1rem 2rem;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  border: none;
}

.primary-btn {
  background: linear-gradient(135deg, #72da37 0%, #4ca819 100%);
  color: #111111;
}

.secondary-btn {
  background: transparent;
  border: 2px solid #72da37;
  color: #72da37;
}

/* Services Section */
.services-section {
  padding: 6rem 2rem;
}

.section-header {
  text-align: center;
  margin-bottom: 4rem;
}

.section-header h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  background: linear-gradient(135deg, #72da37 0%, #4ca819 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section-header p {
  color: #999;
  font-size: 1.2rem;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

/* Service Card */
.service-card {
  background: #1a1a1a;
  border-radius: 16px;
  padding: 2rem;
  cursor: pointer;
  position: relative;
  border: 1px solid #333;
  overflow: hidden;
  transition: all 0.3s ease;
}

.service-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: var(--service-gradient, linear-gradient(135deg, #72da37 0%, #4ca819 100%));
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease;
}

.service-card:hover::before {
  transform: scaleX(1);
}

.service-icon {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  background: var(--service-gradient, linear-gradient(135deg, #72da37 0%, #4ca819 100%));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.service-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #fff;
}

.service-description {
  color: #999;
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.service-tech-stack {
  display: flex;
  gap: 0.8rem;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
}

.tech-tag {
  padding: 0.4rem 0.8rem;
  background: rgba(114, 218, 55, 0.1);
  color: #72da37;
  border-radius: 6px;
  font-size: 0.9rem;
  font-weight: 500;
}

.service-arrow {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  color: #72da37;
  font-size: 1.2rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero-text-building { font-size: 3rem; }
  .hero-text-the { font-size: 2rem; }
  .hero-text-future { font-size: 2.8rem; }
  .hero-text-with { font-size: 2rem; }
  .hero-text-technology { font-size: 2.5rem; }

  .hero-title {
    font-size: 2.5rem;
  }

  .hero-subtitle {
    font-size: 1.25rem;
    padding: 0 1rem;
  }

  .hero-cta {
    flex-direction: column;
  }

  .services-grid {
    grid-template-columns: 1fr;
  }

  .service-card {
    padding: 1.5rem;
  }
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Hover Effects */
.primary-btn:hover {
  box-shadow: 0 4px 15px rgba(114, 218, 55, 0.4);
}

.secondary-btn:hover {
  background: rgba(114, 218, 55, 0.1);
}

/* Glass Effect for Cards */
.service-card:hover {
  backdrop-filter: blur(10px);
  border-color: rgba(114, 218, 55, 0.5);
  box-shadow: 0 8px 32px rgba(114, 218, 55, 0.1);
}

/* Loading States */
.loading {
  opacity: 0.7;
  pointer-events: none;
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
  * {
    animation: none !important;
    transition: none !important;
  }
}

.title-segment {
  cursor: pointer;
  display: inline-block;
  user-select: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
}

/* For touch devices */
@media (hover: none) {
  .title-segment {
    -webkit-tap-highlight-color: transparent;
  }
}

.hero-word {
  cursor: pointer;
  position: relative;
  transition: color 0.3s ease;
}

.hero-word:hover {
  color: #72da37;
}

@media (max-width: 480px) {
  .hero-text-building { font-size: 2.5rem; }
  .hero-text-the { font-size: 1.5rem; }
  .hero-text-future { font-size: 2.3rem; }
  .hero-text-with { font-size: 1.5rem; }
  .hero-text-technology { font-size: 2rem; }
  .hero-subtitle {
    font-size: 1.1rem;
  }
}

/* Dark Mode Support */
@media (prefers-color-scheme: dark) {
  .hero-title {
    background: linear-gradient(135deg, #ffffff 0%, #cccccc 100%);
    -webkit-background-clip: text;
  }
  .hero-subtitle {
    color: #a0a0a0;
  }
}