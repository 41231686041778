.cloud-page-container {
  padding: 0;
  min-height: 100vh;
  background: linear-gradient(135deg, #f6f9fc 0%, #eef2f7 100%);
  overflow: hidden;
  padding-top: 70px;
}

/* Hero Section */
.cloud-hero-section {
  background: linear-gradient(120deg, #72da37 0%, #026440 100%);
  padding: 6rem 2rem;
  position: relative;
  overflow: hidden;
}

.cloud-hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: 
    radial-gradient(circle at 20px 20px, rgba(255, 255, 255, 0.1) 2%, transparent 0%),
    radial-gradient(circle at 60px 60px, rgba(255, 255, 255, 0.1) 2%, transparent 0%);
  background-size: 80px 80px;
  opacity: 0.3;
  animation: float 20s linear infinite;
}

.cloud-hero-content {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.cloud-hero-title {
  font-size: 4rem;
  margin-bottom: 1.5rem;
  font-weight: 800;
  background: linear-gradient(to right, #fff, #e0e0e0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: left;
}

.cloud-hero-subtitle {
  font-size: 1.4rem;
  line-height: 1.8;
  color: rgba(255, 255, 255, 0.9);
  max-width: 600px;
  text-align: left;
  margin: 0;
}

.cloud-hero-icon {
  position: absolute;
  right: 5%;
  top: 50%;
  transform: translateY(-50%);
  font-size: 15rem;
  color: rgba(255, 255, 255, 0.1);
  animation: float 3s ease-in-out infinite;
}

/* Features Section */
.cloud-features-section {
  padding: 5rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2.5rem;
  padding: 2rem 0;
}

.feature-card {
  background: rgba(255, 255, 255, 0.9);
  padding: 2.5rem;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(1, 50, 32, 0.1);
  backdrop-filter: blur(10px);
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.feature-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(1, 50, 32, 0.15);
  border-color: #72da37;
}

/* Benefits Section */
.cloud-benefits {
  margin-top: 4rem;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
}

.cloud-benefits h3 {
  text-align: center;
  margin-bottom: 2rem;
  color: #72da37;
  font-size: 2rem;
}

.benefits-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.benefit-item {
  text-align: center;
  padding: 1.5rem;
}

.benefit-item h4 {
  color: #72da37;
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.benefit-item p {
  color: #666;
  line-height: 1.6;
}

/* Tech Stack Section */
.tech-stack-section {
  background: linear-gradient(to right, #72da37, #026440);
  padding: 4rem 2rem;
  margin: 4rem 0;
  color: white;
  border-radius: 20px;
}

.tech-stack-section h3 {
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: center;
}

.tech-tags {
  display: flex;
  gap: 1.5rem;
  justify-content: center;
  flex-wrap: wrap;
}

.tech-tag {
  background: rgba(255, 255, 255, 0.1);
  color: white;
  padding: 1rem 2rem;
  border-radius: 30px;
  font-size: 1.1rem;
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: all 0.3s ease;
}

.tech-tag:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

/* CTA Section */
.cloud-cta-section {
  text-align: center;
  padding: 4rem 2rem;
  background: white;
}

.primary-btn, .secondary-btn {
  padding: 1.2rem 3rem;
  border-radius: 30px;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 1px;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: pointer;
}

.primary-btn {
  background: linear-gradient(to right, #72da37, #026440);
  color: white;
  border: none;
  margin-right: 1rem;
}

.secondary-btn {
  background: transparent;
  border: 2px solid #72da37;
  color: #72da37;
}

/* Animations */
@keyframes float {
  0% { transform: translateY(-50%) scale(1); }
  50% { transform: translateY(-50%) scale(1.1); }
  100% { transform: translateY(-50%) scale(1); }
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .cloud-hero-section {
    padding: 4rem 1.5rem;
  }

  .cloud-hero-title {
    font-size: 2.5rem;
    text-align: center;
  }

  .cloud-hero-subtitle {
    text-align: center;
    margin: 0 auto;
    font-size: 1.1rem;
  }

  .cloud-hero-icon {
    display: none;
  }

  .features-grid,
  .benefits-grid {
    grid-template-columns: 1fr;
    padding: 1rem;
  }

  .tech-stack-section {
    padding: 3rem 1rem;
    margin: 2rem 1rem;
  }

  .tech-tag {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
  }

  .cloud-cta-section {
    padding: 3rem 1rem;
  }

  .primary-btn, .secondary-btn {
    display: block;
    width: 100%;
    margin: 1rem 0;
  }
}

/* Dark Mode */
@media (prefers-color-scheme: dark) {
  .cloud-page-container {
    background: linear-gradient(135deg, #1a1a1a 0%, #2d2d2d 100%);
  }

  .feature-card,
  .cloud-benefits {
    background: rgba(45, 45, 45, 0.9);
    border-color: rgba(255, 255, 255, 0.1);
  }

  .feature-card h3,
  .cloud-benefits h3,
  .benefit-item h4 {
    color: #fff;
  }

  .feature-card p,
  .benefit-item p {
    color: #ccc;
  }

  .section-header h2 {
    color: #fff;
  }

  .section-header p {
    color: #ccc;
  }

  .cloud-cta-section {
    background: #1a1a1a;
  }

  .secondary-btn {
    border-color: #fff;
    color: #fff;
  }
} 