/* Base Navbar Styles */
nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  background: #111111;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  z-index: 1000;
  border-bottom: 1px solid #333;
}

/* Logo Styles */
.logo-container {
  display: flex;
  align-items: center;
  text-decoration: none;
  position: relative;
  z-index: 10;
}

.logo-wrapper {
  position: relative;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: filter 0.3s ease;
}

.back-logo,
.logo {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.back-logo {
  opacity: 0.8;
  filter: drop-shadow(0 0 10px rgba(114, 218, 55, 0.3));
}

.logo {
  filter: drop-shadow(0 0 5px rgba(2, 100, 64, 0.5));
}

/* Hover effects */
.logo-wrapper:hover .back-logo {
  filter: drop-shadow(0 0 15px rgba(114, 218, 55, 0.5));
}

.logo-wrapper:hover .logo {
  filter: drop-shadow(0 0 8px rgba(2, 100, 64, 0.7));
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  .back-logo {
    filter: drop-shadow(0 0 12px rgba(114, 218, 55, 0.4));
  }
  
  .logo {
    filter: drop-shadow(0 0 6px rgba(2, 100, 64, 0.6));
  }
  
  .logo-wrapper:hover .back-logo {
    filter: drop-shadow(0 0 18px rgba(114, 218, 55, 0.6));
  }
  
  .logo-wrapper:hover .logo {
    filter: drop-shadow(0 0 10px rgba(2, 100, 64, 0.8));
  }
}

/* Responsive design */
@media (max-width: 1024px) {
  .logo-wrapper {
    width: 45px;
    height: 45px;
  }
}

@media (max-width: 768px) {
  .logo-wrapper {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 480px) {
  .logo-wrapper {
    width: 35px;
    height: 35px;
    margin-right: 0.5rem;
  }
  
  .back-logo,
  .logo {
    filter: drop-shadow(0 0 5px rgba(114, 218, 55, 0.2));
  }
}

/* Prevent logo from being too small on very small screens */
@media (max-width: 320px) {
  .logo-wrapper {
    width: 32px;
    height: 32px;
  }
}

/* Optimize for high-DPI screens */
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .back-logo,
  .logo {
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
  }
}

/* Reduce motion if user prefers */
@media (prefers-reduced-motion: reduce) {
  .logo-wrapper,
  .back-logo,
  .logo {
    transition: none;
    animation: none;
  }
}

.brand-text {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: 'Syncopate', sans-serif;
  letter-spacing: 2px;
}

.brand-number {
  font-size: 1.8rem;
  font-weight: 700;
  background: linear-gradient(135deg, #72da37 0%, #026440 100%);
  -webkit-background-clip: text;
  color: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
}

.brand-divider {
  font-size: 1.5rem;
  opacity: 0.3;
  margin: 0 0.3rem;
  color: #666;
}

.brand-name {
  font-size: 1.6rem;
  font-weight: 700;
  background: linear-gradient(135deg, #026440 0%, #72da37 100%);
  -webkit-background-clip: text;
  color: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* Menu Button */
.menu-button {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 1003;
}

.bar {
  display: block;
  width: 25px;
  height: 2px;
  background: #72da37;
  margin: 5px 0;
  transition: 0.4s;
}

/* Desktop Menu */
.menu-items {
  display: flex;
  gap: 2rem;
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu-items a {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #999;
  text-decoration: none;
  font-size: 1rem;
  transition: 0.3s ease;
}

.menu-items a:hover,
.menu-items a.active {
  color: #72da37;
}

.nav-icon {
  font-size: 1.2rem;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .menu-button {
    display: block;
  }

  .menu-button.open .bar1 {
    transform: rotate(-45deg) translate(-5px, 6px);
  }

  .menu-button.open .bar2 {
    opacity: 0;
  }

  .menu-button.open .bar3 {
    transform: rotate(45deg) translate(-5px, -6px);
  }

  .menu-items {
    position: fixed;
    top: 0;
    right: -100%;
    width: 300px;
    height: 100vh;
    background: #111111;
    flex-direction: column;
    padding: 100px 2rem 2rem;
    transition: 0.4s ease;
    border-left: 1px solid #333;
    z-index: 1002;
  }

  .menu-items.open {
    right: 0;
  }

  .menu-items li {
    opacity: 0;
    transform: translateX(30px);
    transition: 0.3s ease;
    position: relative;
    z-index: 1003;
  }

  .menu-items.open li {
    opacity: 1;
    transform: translateX(0);
  }

  /* Staggered animation for menu items */
  .menu-items.open li:nth-child(1) { transition-delay: 0.1s; }
  .menu-items.open li:nth-child(2) { transition-delay: 0.2s; }
  .menu-items.open li:nth-child(3) { transition-delay: 0.3s; }
  .menu-items.open li:nth-child(4) { transition-delay: 0.4s; }
  .menu-items.open li:nth-child(5) { transition-delay: 0.5s; }

  .menu-items a {
    font-size: 1.2rem;
    padding: 1rem 0;
  }

  .menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1001;
    opacity: 0;
    animation: fadeIn 0.3s ease forwards;
  }
}

/* Animations */
@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Hover Effects */
.menu-items a:hover .nav-icon {
  transform: scale(1.1);
  transition: 0.3s ease;
}

.logo-container:hover .logo {
  animation-play-state: paused;
}

/* Hover effects */
.brand-text:hover .brand-number {
  text-shadow: 2px 2px 8px rgba(114, 218, 55, 0.3);
}

.brand-text:hover .brand-name {
  text-shadow: 2px 2px 8px rgba(2, 100, 64, 0.3);
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  .brand-divider {
    color: #999;
  }
  
  .brand-text:hover .brand-number,
  .brand-text:hover .brand-name {
    text-shadow: 2px 2px 12px rgba(114, 218, 55, 0.4);
  }
}

/* Responsive design */
@media (max-width: 768px) {
  .brand-number {
    font-size: 1.5rem;
  }
  
  .brand-name {
    font-size: 1.3rem;
  }
  
  .brand-divider {
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  .brand-number {
    font-size: 1.3rem;
  }
  
  .brand-name {
    font-size: 1.1rem;
  }
  
  .brand-divider {
    font-size: 1rem;
    margin: 0 0.2rem;
  }
}
