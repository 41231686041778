.chat-controls-container {
  position: relative;
  padding: 1rem;
  background: var(--bg-primary-light);
  border-top: 1px solid var(--border-light);
  z-index: 100;
}

.chat-controls {
  display: flex;
  gap: 0.75rem;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  background: var(--bg-secondary-light);
  padding: 0.75rem;
  border-radius: 24px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

.input-container {
  flex: 1;
  position: relative;
}

.chat-input {
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 20px;
  font-size: 1rem;
  background: transparent;
  color: var(--text-primary-light);
  outline: none;
}

.chat-input::placeholder {
  color: var(--text-secondary-light);
}

.action-button {
  padding: 0.75rem;
  border: none;
  background: transparent;
  color: var(--text-secondary-light);
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-button:hover {
  background: var(--bg-hover-light);
  color: var(--primary-color);
}

.send-button {
  padding: 0.75rem;
  border: none;
  border-radius: 20px;
  background: var(--primary-color);
  color: white;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.send-button:hover:not(:disabled) {
  transform: scale(1.05);
  background: var(--primary-color-dark);
}

.send-button:disabled {
  background: var(--text-secondary-light);
  cursor: not-allowed;
  opacity: 0.7;
}

.emoji-picker {
  position: absolute;
  bottom: 100%;
  left: 1rem;
  background: var(--bg-primary-light);
  border-radius: 12px;
  box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.15);
  margin-bottom: 1rem;
  width: 320px;
  overflow: hidden;
  animation: slideUp 0.2s ease;
}

.emoji-picker-header {
  padding: 1rem;
  font-weight: 500;
  color: var(--text-primary-light);
  border-bottom: 1px solid var(--border-light);
}

.emoji-grid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 0.5rem;
  padding: 1rem;
  max-height: 200px;
  overflow-y: auto;
}

.emoji-button {
  padding: 0.75rem;
  border: none;
  background: transparent;
  font-size: 1.25rem;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.emoji-button:hover {
  background: var(--bg-hover-light);
  transform: scale(1.1);
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Dark mode */
@media (prefers-color-scheme: dark) {
  .chat-controls-container {
    background: var(--bg-primary-dark);
    border-color: var(--border-dark);
  }

  .chat-controls {
    background: var(--bg-secondary-dark);
  }

  .chat-input {
    color: var(--text-primary-dark);
  }

  .chat-input::placeholder {
    color: var(--text-secondary-dark);
  }

  .emoji-picker {
    background: var(--bg-primary-dark);
    box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.3);
  }

  .emoji-picker-header {
    color: var(--text-primary-dark);
    border-color: var(--border-dark);
  }

  .emoji-button:hover {
    background: var(--bg-hover-dark);
  }
}

/* Mobile Responsive */
@media (max-width: 768px) {
  .chat-controls-container {
    padding: 0.75rem;
  }

  .chat-controls {
    padding: 0.5rem 0.75rem;
    gap: 0.5rem;
  }

  .action-button,
  .send-button {
    padding: 0.6rem;
  }

  .chat-input {
    padding: 0.6rem;
    font-size: 16px;
  }

  .emoji-picker {
    width: calc(100vw - 2rem);
    left: 50%;
    transform: translateX(-50%);
  }

  .emoji-grid {
    grid-template-columns: repeat(6, 1fr);
  }
}
