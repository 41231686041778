.chat-list-container {
  width: 280px;
  height: 100%;
  background: var(--bg-primary-light);
  border-right: 1px solid var(--border-light);
  display: flex;
  flex-direction: column;
}

.chat-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid var(--border-light);
}

.chat-list-header h3 {
  margin: 0 0 1rem 0;
  color: var(--text-primary-light);
}

.search-input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--border-light);
  border-radius: 4px;
  background: var(--bg-secondary-light);
  color: var(--text-primary-light);
}

.chat-list {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
}

.user-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.user-item {
  display: flex;
  align-items: center;
  padding: 0.75rem;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 0.5rem;
  background: var(--bg-secondary-light);
  transition: all 0.2s ease;
}

.user-item:hover {
  background: var(--bg-hover-light);
}

.user-item.selected {
  background: var(--primary-color);
  color: white;
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--primary-color);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-right: 1rem;
}

.user-info {
  flex: 1;
  overflow: hidden;
}

.user-name {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.status-dot {
  font-size: 0.5rem;
}

.status-dot.online {
  color: #10b981;
}

.status-dot.offline {
  color: #6b7280;
}

.last-message {
  font-size: 0.85rem;
  color: var(--text-secondary-light);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Dark mode */
@media (prefers-color-scheme: dark) {
  .chat-list-container {
    background: var(--bg-primary-dark);
    border-color: var(--border-dark);
  }

  .search-input {
    background: var(--bg-secondary-dark);
    border-color: var(--border-dark);
    color: var(--text-primary-dark);
  }

  .user-item {
    background: var(--bg-secondary-dark);
  }

  .user-item:hover {
    background: var(--bg-hover-dark);
  }

  .last-message {
    color: var(--text-secondary-dark);
  }
}

/* Loading and error states */
.chat-list-loading,
.chat-list-error,
.no-users-text {
  padding: 1rem;
  text-align: center;
  color: var(--text-secondary-light);
}

.loading-spinner {
  margin: 0 auto 1rem;
}

.close-button {
  width: 13.33px;
  height: 13.33px;
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  color: var(--text-primary-light);
  display: flex;
  align-items: center;
  justify-content: center;
}
