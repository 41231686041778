/* Services Page Specific Styles */
.services-page-container {
  max-width: 1400px;
  margin: 80px auto 0;
  padding: 2rem;
  min-height: 100vh;
  background: #111111;
}

/* Services Search */
.services-search-container {
  max-width: 600px;
  margin: 0 auto 3rem;
}

.services-search-input {
  width: 100%;
  padding: 1rem 1.5rem;
  border: 1px solid #333;
  border-radius: 8px;
  font-size: 1rem;
  background: #1a1a1a;
  color: #fff;
  transition: all 0.3s ease;
}

.services-search-input:focus {
  outline: none;
  border-color: #72da37;
  box-shadow: 0 0 0 3px rgba(114, 218, 55, 0.1);
}

/* Services Page Grid */
.services-page-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 2rem;
}

/* Services Page Card */
.services-page-card {
  background: #1a1a1a;
  border: 1px solid #333;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.services-page-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
  border-color: #72da37;
}

/* Services Card Header */
.services-page-header {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.services-page-icon {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #72da37 0%, #4ca819 100%);
  border-radius: 10px;
  font-size: 1.5rem;
  color: #111111;
}

.services-page-header h3 {
  font-size: 1.3rem;
  font-weight: 600;
  color: #fff;
  margin: 0;
}

/* Services Description */
.services-page-description {
  color: #999;
  line-height: 1.6;
}

/* Services Tech Stack */
.services-page-tech-stack {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.services-page-tech-tag {
  padding: 0.4rem 0.8rem;
  background: rgba(114, 218, 55, 0.1);
  color: #72da37;
  border-radius: 6px;
  font-size: 0.9rem;
  font-weight: 500;
}

/* Services Features */
.services-page-features {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.services-page-feature-item {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  color: #ccc;
  padding: 0.5rem;
  border-radius: 6px;
  transition: all 0.3s ease;
}

.services-page-feature-item:hover {
  background: rgba(114, 218, 55, 0.05);
}

.services-page-feature-icon {
  color: #72da37;
  font-size: 0.9rem;
}

/* Services Button */
.services-page-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.8rem 1.2rem;
  background: linear-gradient(135deg, #72da37 0%, #4ca819 100%);
  color: #111111;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: auto;
}

.services-page-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(114, 218, 55, 0.3);
}

/* Responsive */
@media (max-width: 768px) {
  .services-page-grid {
    grid-template-columns: 1fr;
  }

  .services-page-card {
    padding: 1.5rem;
  }
}

/* Placeholder color */
.services-search-input::placeholder {
  color: #666;
}
