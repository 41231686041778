/* Reset and General Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Centering the chat login container */
.chat-login-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Full viewport height */
  background-color: #f2f4f7; /* Optional background */
}

/* Chat Login Container */
.chat-login-container {
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1);
  padding: 40px 30px;
  width: 100%;
  max-width: 400px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.chat-login-container:hover {
  transform: translateY(-5px);
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.15);
}

/* Heading */
.chat-login-container h2 {
  font-size: 24px;
  color: #333333;
  margin-bottom: 20px;
  font-weight: bold;
  letter-spacing: 1px;
  position: relative;
  padding-bottom: 5px;
}

.chat-login-container h2::after {
  content: "";
  position: absolute;
  width: 40px;
  height: 3px;
  background-color: #4ea82a;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

/* Input Field */
.chat-login-container input[type="text"] {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border-radius: 10px;
  border: 2px solid #ddd;
  margin-bottom: 20px;
  outline: none;
  transition: border-color 0.3s;
}

.chat-login-container input[type="text"]:focus {
  border-color: #4ea82a;
}

/* Enter Button */
.chat-login-container button {
  width: 100%;
  padding: 12px;
  background-color: #000000;
  color: #ffffff;
  font-size: 16px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

.chat-login-container button:hover {
  background-color: #4ea82a;
}

/* Room Rules Section */
.room-rules {
  margin-top: 30px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
  color: #333333;
  font-size: 14px;
  text-align: left;
}

.room-rules h3 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #4ea82a;
}

.room-rules ul {
  list-style-type: disc;
  margin-left: 20px;
}

.room-rules li {
  margin-bottom: 8px;
}

.room-rules p {
  margin-top: 10px;
  color: #555555;
  font-style: italic;
  font-size: 13px;
  text-align: center;
}
